import { forwardRef, useImperativeHandle, useContext } from "react";
import { useIntl } from "react-intl";
import moment from 'moment';

import { EnumActionType, EnumASCloudAPIType, timezoneScaleCount, EnumDeviceType } from './ASConfig';
import { useASConfig, timeScaleToStr, hexToTimeScale } from '../ASUtils/ASUtils';
import { AppContext } from '../../Utils';

const AuditLog = forwardRef(({...props}, ref) => {
    const excludeKeys = ['audit_memo', 'action_type', 'api_cmd', 'dirty', 'error_code', 'invalid', 'deletable', 'readOnly', 'id',   // general
                        'device_id', 'device_pwd', 'device_status', 'fakePassword', 'version_status',   // device
                        'gate_id', 'cmn_pwd', 'nt_ids', 'device_id', 'gate_id', 'expired', 'gate_ldn', 'gate_status', 'last_log_id', 'last_log_utc', 'live_cam', 'ln_exp', 'ln_id', 'ln_keepday', 'ln_keepday_ss', 'log_img', 'pin_data', 'privilege',
                        'gate_alarm_access_denied', 'gate_alarm_continuous_time', 'gate_alarm_fire_alarm', 'gate_alarm_forced_open', 'gate_alarm_held_open', 'gate_alarm_tamper',   // gate
                        'u_user_id', 'u_photo', 'u_photo_s', 'u_photo_action_type', 'sort_field', 'sort_type',  // user
                        'c_id', 'c_add_utc', 'c_passcode', 'c_type',  // card
                        'u_vehicles',   // vehicle
                        'nt_id', 'nt_mobile_id', 'subject', 'content', 'nts_sms',    // alert
                        'request_type'   // access log
                    ];
    const jsonStringKeys = ['settings', 'auth_schedule', 'nt_settings', 'sd_rules'];
    const booleanKeys = ['enable', 'nt_disable', 'fixedTimestamp', 'a_read', 'a_write', 'a_excute'];
    const ASConfig = useASConfig();
    const { findDeviceGate, organizationMap } = useContext(AppContext);
    const intl = useIntl();

    const getDstDescription = (dstData) => {
        if (typeof(dstData) !== 'object') return '';
        return `${moment.months(dstData.month - 1)} / ${ASConfig.getOrdinalWeekName(dstData.weekNum)} / ${moment.weekdays(dstData.weekDay)} / ${getAlertTriggerTime(dstData.time)}`;
    };

    const getAccessRuleNames = (ar_ids) => {
        if (!Array.isArray(ar_ids)) return [];
        var arr = [];
        ar_ids.forEach((ar_id) => {
            var ar_name = ASConfig.getAccessRuleName(ar_id);
            if (ar_name) arr.push(ar_name);
        });
        return arr;
    };

    const getTimezoneDescription = (scale) => {
        if (scale.length !== timezoneScaleCount) return {};

        var descrips = {}, type, i = 0, j;
        while (i < timezoneScaleCount) {
            type = parseInt(scale[i]);
            if (type) {
                var start = i, 
                    end = timezoneScaleCount - 1;
                for (j = i + 1; j < timezoneScaleCount; j++) {
                    if (scale[j] !== scale[i]) {
                        end = j - 1;
                        break;
                    }
                }
                if (!Array.isArray(descrips[type])) {
                    descrips[type] = [];
                }

                descrips[type].push(`${timeScaleToStr(start)} - ${timeScaleToStr(end + 1)}`);
                i = end + 1;
                continue;
            }
            i++;
        }
        return descrips;
    };

    const getAuthTimezone = (scale) => {
        var descrips = getTimezoneDescription(scale);
        return {
            [intl.formatMessage({id: 'release_mode'})]: (descrips['1'] || []).join(', '),
            [intl.formatMessage({id: 'release_by_card_mode'})]: (descrips['2'] || []).join(', ')
        };
    };

    const getAccessRuleTimezone = (scale) => {
        var descrips = getTimezoneDescription(hexToTimeScale(scale));
        return (descrips['1'] || []).join(', ');
    };

    const getAccessRuleGates = (ar_gates) => {
        if (!Array.isArray(ar_gates)) return [];
        var arr = [];
        ar_gates.forEach((item) => {
            var {device, gate} = findDeviceGate(item.device_id, item.device_mac, item.gate_id);
            if (device && gate) {
                arr.push(ASConfig.getFullGateName({
                    device_name: device.device_name,
                    gate_name: gate.gate_name,
                    gate_dir: gate.gate_dir
                }));
            } else if (device) {
                arr.push(device.device_name);
            }
        });
        return arr;
    };

    const getQueryLogGates = (device_gates) => {
        if (!Array.isArray(device_gates)) return [];
        var arr = [];
        device_gates.forEach((item) => {
            var [device_id, gate_id] = item.toString().split('_');
            if (device_id && gate_id) {
                arr.push({
                    device_id: parseInt(device_id),
                    gate_id: parseInt(gate_id)
                });
            }
        });
        return getAccessRuleGates(arr);
    };

    const getAlertTriggerTime = (value) => {
        return `${Math.floor(value / 60).toString().padStart(2, '0')}:${Math.floor(value % 60).toString().padStart(2, '0')}`;
    };

    const getAlertTriggerSchdule = (nt_weeks) => {
        if (!Array.isArray(nt_weeks)) return [];
        var arr = [];
        nt_weeks.forEach((day) => {
            arr.push(ASConfig.getSpecialDayName(day));
        });
        return arr;
    };

    const getAlertTriggerMessages = (nt_msgs) => {
        if (!Array.isArray(nt_msgs)) return [];
        var arr = [];
        nt_msgs.forEach((msg_id) => {
            arr.push(ASConfig.getMessage(msg_id));
        });
        return arr;
    };

    const getAlertTriggerUsers = (nt_users) => {
        if (!Array.isArray(nt_users)) return [];
        var arr = [];
        nt_users.forEach((user) => {
            if (user.u_name) {
                arr.push(user.u_name);
            }
        });
        return arr;
    };

    const getAlertPushNotifications = (nts_push) => {
        if (!Array.isArray(nts_push?.mobile_ids)) return [];
        var arr = [];
        nts_push.mobile_ids.forEach((item) => {
            var accountName = ASConfig.getAccountName(item.m_owner_id);
            if (accountName) arr.push(accountName);
        });
        return arr;
    };

    const getAlertApproach = (nt_status) => {
        var arr = [];
        if (nt_status?.nts_email) {
            arr.push(intl.formatMessage({id: 'send_email_alert'}));
        }
        if (nt_status?.nts_push) {
            arr.push(intl.formatMessage({id: 'push_notification'}));
        }
        return arr;
    };

    const getSpecialDayRules = (sd_rules) => {
        var ruels = {};
        Object.entries(sd_rules).forEach(([key, item]) => {
            var arr = [];
            if (Array.isArray(item)) {
                item.forEach((value) => {
                    arr.push(ASConfig.getSpecialDayRuleLabel(key, value));
                });
            }
            ruels[ASConfig.getSpecialDayRuleName(key)] = arr.join(', ');
        });
        return ruels;
    };

    const getRegionAdmins = (rg_admins) => {
        if (!Array.isArray(rg_admins)) return [];
        var arr = [];
        rg_admins.forEach((id) => {
            var accountName = ASConfig.getAccountName(id);
            if (accountName) arr.push(accountName);
        });
        return arr;
    };

    const getGateFormatDescription = (gateFormat, gateSeparator) => {
        return ASConfig.getFullGateName({
            gateFormat, separator: gateSeparator,
            device_name: intl.formatMessage({id: 'device'}),
            gate_name: intl.formatMessage({id: 'gate'}),
            dir_name: intl.formatMessage({id: 'direction'}),
        });
    };

    const getCopyAuthDescription = (devices) => {
        if (!Array.isArray(devices)) return [];
        var arr = [];
        devices.forEach((item) => {
            var {device} = findDeviceGate(item.device_id);
            if (!device || !Array.isArray(item.gates)) return;

            var gates = [];
            item.gates.forEach(subItem => {
                var gate = device.gates.find(gate => gate.gate_id === subItem.gate_id);
                if (!gate) return;

                gates.push({
                    gate_name: ASConfig.getGateName(EnumDeviceType.Controller, gate.gate_name, subItem.gate_dir),
                    ...getAuditMemo({auth_schedule: subItem.auth_schedule})
                });
            });

            arr.push({
                gates,
                device_name: device.device_name
            });
        });
        return arr;
    };

    const getOrganizationName = (og_id, source) => {
        var og_name;
        (source || organizationMap || []).some(item => {
            if (item.og_id === og_id) {
                og_name = item.og_name;
            } else if (Array.isArray(item.children) && item.children.length > 0) {
                og_name = getOrganizationName(og_id, item.children);
            }

            return !!og_name;
        });
        return og_name;
    };

    const getScenarioGates = (snr_gates) => {
        var devices = [];
        snr_gates.forEach(_device => {
            var { device } = findDeviceGate(_device.device_id);
            if (!device) return;

            var gates = [];
            _device.gate_ids.forEach((gate_id, index) => {
                if (index > 0 && gate_id % 2 === 1 && _device.gate_ids[index - 1] === (gate_id - 1)) {
                    return;   // ignore exit door
                }
                var gate = device.gates.find(gate => gate.gate_id === gate_id);
                if (gate) {
                    gates.push(gate.gate_name);
                }
            });

            devices.push({
                device_name: device.device_name,
                gates
            });
        });
        return devices;
    };

    const getAuditMemo = (params) => {
        if (typeof(params) !== 'object') return null;
        var memo = {};
        Object.entries(params).forEach(([key, item]) => {
            if (excludeKeys.includes(key)) return;

            var val = item;

            if (jsonStringKeys.includes(key)) {
                try {
                    val = JSON.parse(item);
                } catch (e) { return; }
            }

            switch (key) {
                case 'rg_id':
                    memo['rg_name'] = ASConfig.getRegionName(val); break;
                case 'device_type':
                    memo[key] = ASConfig.getDeviceTypeName(val); break;
                case 'device_model':
                    memo[key] = ASConfig.getDeviceModelName(params['device_type'], val); break;
                case 'gate_dir':
                    memo[key] = ASConfig.getDirName(val); break;
                case 'cmn_pwd':
                case 'c_pin_code':
                    memo[key] = val? '****' : ''; break;
                case 'dstStart':
                case 'dstEnd':
                    if (params.dst) {
                        var dstDescription = getDstDescription(val);
                        if (dstDescription)  memo[key] = dstDescription;
                    }
                    break;

                case 'ar_ids':
                    memo['ar_names'] = getAccessRuleNames(val); break;
                case 'specialDayId':
                case 'sd_id':
                    memo['sd_name'] = ASConfig.getSpecialDayName(val); break;
                case 'timeZone':
                    memo[key] = getAuthTimezone(val); break;

                case 'c_active':
                    memo[key] = ASConfig.getCardStatusName(val); break;
                case 'v_active':
                    memo[key] = ASConfig.getVehicleStatusName(val); break;
                case 'c_code':
                    memo[key] = ASConfig.getCardCodeName(val); break;
                case 'c_start_time':
                case 'c_end_time':
                case 'v_start_time':
                case 'v_end_time':
                case 'start_time':
                case 'end_time':
                    memo[key] = ASConfig.getFormatedTime(val); break;

                case 'ar_id':
                    memo['ar_name'] = ASConfig.getAccessRuleName(val); break;
                case 'ar_auth_mode':
                    memo[key] = ASConfig.getAccessAuthModeName(val); break;
                case 'time':
                    memo[key] = getAccessRuleTimezone(val); break;
                case 'ar_gates':
                case 'nt_gates':
                    memo[key] = getAccessRuleGates(val); break;

                case 'nt_start_time':
                case 'nt_end_time':
                    memo[key] = getAlertTriggerTime(val); break;
                case 'nt_weeks':
                    memo[key] = getAlertTriggerSchdule(val); break;
                case 'nt_msgs':
                case 'msg_ids':
                    memo[key.replace('_id', '')] = getAlertTriggerMessages(val); break;
                case 'nt_users':
                    memo[key] = getAlertTriggerUsers(val); break;
                case 'nt_owner_id':
                case 'sd_owner_id':
                case 'snr_owner_id':
                    memo[key.replace('_id', '')] = ASConfig.getAccountName(val); break;
                case 'nts_push':
                    memo[key] = getAlertPushNotifications(val); break;
                case 'nt_status':
                    memo[key] = getAlertApproach(val); break;
                case 'to_triggered_user':
                    memo[key] = val ? intl.formatMessage({id: 'mail_to_triggered_user'}) : false; break;

                case 'sd_rules':
                    memo[key] = getSpecialDayRules(val); break;

                case 'rg_admins':
                    memo[key] = getRegionAdmins(val); break;

                case 'gateFormat':
                    memo[key] = getGateFormatDescription(val, params['gateSeparator']); break;

                case 'devices': // only for copy authentication schedule (not for gate setting)
                    memo[key] = getCopyAuthDescription(val); break;


                case 'device_gates':
                    memo[key] = getQueryLogGates(val); break;
                case 'u_og1_id':
                case 'u_og2_id':
                case 'u_og3_id':
                    var newKey = key.replace('_id', '');
                    if (typeof(params[newKey]) !== 'undefined') {
                        memo[newKey] = params[newKey];
                    } else {
                        memo[newKey] = getOrganizationName(parseInt(val));
                    }
                    break;

                case 'snr_id':
                    memo['snr_name'] = ASConfig.getScenarioName(val); break;
                case 'snr_gates':
                    memo[key] = getScenarioGates(val); break;

                case 'sl_pd':
                    memo['sl_has_pd'] = val ? 1 : 0; break;

                default:
                    if (booleanKeys.includes(key)) {
                        memo[key] = !!val;
                    } else if (Array.isArray(val)) {
                        var arr = [];
                        val.forEach(subItem => {
                            if (typeof(subItem) === 'object') {
                                var temp = getAuditMemo(subItem);
                                if (temp) arr.push(temp);
                            } else {
                                arr.push(subItem);
                            }
                        });
                        memo[key] = arr;
                    } else if (typeof(val) === 'object' && val) {
                        memo[key] = getAuditMemo(val);
                    } else {
                        memo[key] = val;
                    }
                    break;
            }
        });
        return memo;
    };

    const genAuditMemo = (postBody) => {
        if (typeof(postBody) !== 'object') return postBody;
        if (![EnumActionType.Add, EnumActionType.Edit, EnumActionType.Delete, EnumActionType.UpdateEnable].includes(postBody.action_type)) return postBody;

        var auditMemo;
        if (postBody.action_type === EnumActionType.Delete) {
            switch (postBody.api_cmd) {
                case EnumASCloudAPIType.DEVICE:
                    var {device} = findDeviceGate(postBody.device_id, postBody.device_mac);
                    if (device) {
                        auditMemo = getAuditMemo(device);
                    }
                    break;
                case EnumASCloudAPIType.ACCESSRULE:
                    auditMemo = getAuditMemo(ASConfig.getAccessRule(postBody.ar_id));
                    break;
                case EnumASCloudAPIType.NOTIFICATION:
                    auditMemo = getAuditMemo(ASConfig.getAlert(postBody.nt_id));
                    break;
                case EnumASCloudAPIType.SPECIALDAY:
                    auditMemo = getAuditMemo(ASConfig.getSpecialDay(postBody.sd_id));
                    break;
                case EnumASCloudAPIType.SCENARIO:
                    auditMemo = getAuditMemo(postBody);
                    break;
                case EnumASCloudAPIType.SHARE_LINK:
                    auditMemo = getAuditMemo(postBody);
                    break;
                default:
                    break;
            }
        } else {
            auditMemo = getAuditMemo(postBody);
        }

        // userdefined audit memo
        if (typeof(postBody.audit_memo) === 'object') { 
            auditMemo = {
                ...auditMemo,
                ...getAuditMemo(postBody.audit_memo)
            };
        }

        if (auditMemo) {
            postBody.audit_memo = JSON.stringify(auditMemo);
        }

        return postBody;
    };

    useImperativeHandle(ref, () => ({
        genAuditMemo, getAuditMemo
    }));

    return null;
});
export default AuditLog;